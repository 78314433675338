import { MenuItem } from './menu.model';
if(true){ console.log() }

export const MENU_ADMINE: MenuItem[] = [

    {
        id: 1,
        label: 'ACCUEIL',
        isTitle: true
    },

    {
        id: 123,
        label: 'Tableau de bord',
        icon: 'bx-home-circle',
        link: '/TableauDeBord',
  
    },

    {
        id: 123,
        label: 'Mon espace',
        icon: 'bx-home-circle',
        link: '/Monespace',
  
    },


    
    {
        id: 12,
        label: 'TRAVAIL EN GROUPE',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Créer un groupe',
                link: '/Crergroupe',
                parentId: 12
            },
            {
                id: 14,
                label: 'Salon de travail',
                link: '/Salondetravail',
                parentId: 12
            },
        ]
    },





    {
        id: 1,
        label: 'VIE SCOLAIRE',
        isTitle: true
    },

    {
        id: 12,
        label: 'NOTES',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les notes',
                link: '/Enregistrernotes',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les notes',
                link: '/Consulternotes',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les notes',
                link: '/Modifiernotesv',
                parentId: 12
            },
            {
                id: 16,
                label: 'Exporter les notes excel',
                link: '/Exporternotes',
                parentId: 12
            },
            {
                id: 16,
                label: 'Exporter les notes modifier',
                link: '/Exporternotesmodifier',
                parentId: 12
            },
            {
                id: 17,
                label: 'Exporter le PV des notes',
                link: '/ExportPV',
                parentId: 12
            },
            {
                id: 18,
                label: 'Imprimer le bordereau de notes',
                link: '/ImprimBordereauNote',
                parentId: 12
            },
            {
                id: 19,
                label: 'Supprimer les notes',
                link: '/Supimernote',
                parentId: 12
            },
            {
                id: 19,
                label: 'Importer les notes',
                link: '/Importernotes',
                parentId: 12
            },
            {
                id: 19,
                label: 'Débloquer une évaluation',
                link: '/Debloqueraval',
                parentId: 12
            },
            {
                id: 19,
                label: 'Bloquer une évaluation',
                link: '/bloqueraval',
                parentId: 12
            }


            
        ]
    },


    {
        id: 12,
        label: 'DISCIPLINE',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les absences',
                link: '/EnregistrerAbsence',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les absences',
                link: '/ConsulterAbsence',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les absences',
                link: '/ModifAbsence',
                parentId: 12
            },
            {
                id: 16,
                label: 'Enregistrer CD',
                link: '/EnregistrerCD',
                parentId: 12
            },
            {
                id: 17,
                label: 'Consulter CD',
                link: '/ConsulterCD',
                parentId: 12
            },
            {
                id: 18,
                label: 'Modifier CD',
                link: '/ModifierCD',
                parentId: 12
            },
            {
                id: 19,
                label: 'Exporter les absences',
                link: '/Expoerterabs',
                parentId: 12
            }
            ,
            {
                id: 19,
                label: 'Exporter les CD',
                link: '/Expoertercd',
                parentId: 12
            }
        ]
    },




    {
        id: 12,
        label: 'SUIVI DES ELEVES',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Appel en salle de classe',
                link: '/Appeleleve',
                parentId: 12
            },
            {
                id: 13,
                label: 'Valider les absences',
                link: '/Validerappel',
                parentId: 12
            },
            
            {
                id: 13,
                label: "Consulter la validation de l'Appel",
                link: '/Consultervaliderappel',
                parentId: 12
            },
                 
            {
                id: 13,
                label: "Historique des Appels",
                link: '/Touslelsappelssp',
                parentId: 12
            },
            
         
        ]
    },




    {
        id: 1,
        label: 'DONNEES',
        isTitle: true
    },


    {
        id: 12,
        label: 'NOTIFICATION',
        icon: 'bx bx-bell bx-tada',
        subItems: [
            {
                id: 13,
                label: 'Créer une notification',
                link: '/Creernotifications',
                parentId: 12
            },
            {
                id: 13,
                label: 'Mes notifications',
                link: '/Toutesmesnotif',
                parentId: 12
            },
            {
                id: 13,
                label: 'Toutes les notifications',
                link: '/Allnotification',
                parentId: 12
            },
            {
                id: 13,
                label: 'Administrer Toutes les notifications',
                link: '/Administrertouteslesnotif',
                parentId: 12
            },
        ]
    },







    {
        id: 12,
        label: 'PARAMETRE',
        icon: 'bx bx-cog',
        subItems: [
            {
                id: 13,
                label: 'IMPORTER LES DONNEES',
                link: '/Importerdonnee',
                parentId: 12
            },
            {
                id: 14,
                label: 'SUPPRIMER LES DONNEES',
                link: '/Deletedata',
                parentId: 12
            },
            {
                id: 15,
                label: 'SUPPRIMER CLASSE SANS ELEVE',
                link: '/Classesanseleve',
                parentId: 12
            },
            {
                id: 16,
                label: 'ENREGISTRER INFO ETABLISSEMENT',
                link: '/InfoEtablissement',
                parentId: 12
            },
            {
                id: 17,
                label: 'BLOQUER OU DEBLOQUER UN TRIMESTRE',
                link: '/Bloquertrimestre',
                parentId: 12
            },
            {
                id: 18,
                label: 'REMPLISSAGE TABLE ETAT REMPLISSAGE NOTE',
                link: '/Remplirtableetatnote',
                parentId: 12
            },
            {
                id: 18,
                label: 'Enregistrer/Modifier les horaires',
                link: '/Enregistrerhoraire',
                parentId: 12
            },
            {
                id: 19,
                label: 'Remplir id competence NB',
                link: '/Remplirtablecompetence',
                parentId: 12
            }

            
        ]
    },






    {
        id: 12,
        label: 'UTILISATEUR',
        icon: 'bx bx-id-card',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer un utilisateur',
                link: '/EnregistrerUser',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les  utilisateurs',
                link: '/ConsultUser',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier un utilisateur',
                link: '/ModifUser',
                parentId: 12
            },
            {
                id: 16,
                label: "Modifier le type d'un utilisateur",
                link: '/Modiftypeuser',
                parentId: 12
            },
            {
                id: 17,
                label: 'Enseignants et utilisateur',
                link: '/Enseignenttouser',
                parentId: 12
            },
            {
                id: 18,
                label: 'utilisateur et classes',
                link: '/Usersgcenseur',
                parentId: 12
            }
        ]
    },
];
export const MENU_SUPERADMINE: MenuItem[] = [

    {
        id: 1,
        label: 'ACCUEIL',
        isTitle: true
    },

    {
        id: 123,
        label: 'Tableau de bord',
        icon: 'bx-home-circle',
        link: '/TableauDeBord',
  
    },
    {
        id: 123,
        label: 'Mon espace',
        icon: 'bx-home-circle',
        link: '/Monespace',
  
    },


    {
        id: 12,
        label: 'TRAVAIL EN GROUPE',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Créer un groupe',
                link: '/Crergroupe',
                parentId: 12
            },
            {
                id: 14,
                label: 'Salon de travail',
                link: '/Salondetravail',
                parentId: 12
            },
        ]
    },






    {
        id: 1,
        label: 'VIE SCOLAIRE',
        isTitle: true
    },

    {
        id: 12,
        label: 'NOTES',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les notes',
                link: '/Enregistrernotes',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les notes',
                link: '/Consulternotes',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les notes',
                link: '/Modifiernotesv',
                parentId: 12
            },
            {
                id: 16,
                label: 'Exporter les notes excel',
                link: '/Exporternotes',
                parentId: 12
            },
            {
                id: 16,
                label: 'Exporter les notes modifier',
                link: '/Exporternotesmodifier',
                parentId: 12
            },
            {
                id: 17,
                label: 'Exporter le PV des notes',
                link: '/ExportPV',
                parentId: 12
            },
            {
                id: 18,
                label: 'Imprimer le bordereau de notes',
                link: '/ImprimBordereauNote',
                parentId: 12
            },
            {
                id: 19,
                label: 'Supprimer les notes',
                link: '/Supimernote',
                parentId: 12
            },
            {
                id: 19,
                label: 'Importer les notes',
                link: '/Importernotes',
                parentId: 12
            },
            {
                id: 19,
                label: 'Débloquer une évaluation',
                link: '/Debloqueraval',
                parentId: 12
            }
            ,
            {
                id: 19,
                label: 'Bloquer une évaluation',
                link: '/bloqueraval',
                parentId: 12
            }
            
        ]
    },


    {
        id: 12,
        label: 'DISCIPLINE',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les absences',
                link: '/EnregistrerAbsence',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les absences',
                link: '/ConsulterAbsence',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les absences',
                link: '/ModifAbsence',
                parentId: 12
            },
            {
                id: 16,
                label: 'Enregistrer CD',
                link: '/EnregistrerCD',
                parentId: 12
            },
            {
                id: 17,
                label: 'Consulter CD',
                link: '/ConsulterCD',
                parentId: 12
            },
            {
                id: 18,
                label: 'Modifier CD',
                link: '/ModifierCD',
                parentId: 12
            },
            {
                id: 19,
                label: 'Exporter les absences',
                link: '/Expoerterabs',
                parentId: 12
            }
            ,
            {
                id: 19,
                label: 'Exporter les CD',
                link: '/Expoertercd',
                parentId: 12
            }
        ]
    },




    {
        id: 12,
        label: 'SUIVI DES ELEVES',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Appel en salle de classe',
                link: '/Appeleleve',
                parentId: 12
            },
            {
                id: 13,
                label: 'Valider les absences',
                link: '/Validerappel',
                parentId: 12
            },
            {
                id: 13,
                label: "Consulter la validation de l'Appel",
                link: '/Consultervaliderappel',
                parentId: 12
            },
            {
                id: 13,
                label: "Historique des Appels",
                link: '/Touslelsappelssp',
                parentId: 12
            },
        ]
    },




    {
        id: 1,
        label: 'DONNEES',
        isTitle: true
    },


    {
        id: 12,
        label: 'NOTIFICATION',
        icon: 'bx bx-bell bx-tada',
        subItems: [
            {
                id: 13,
                label: 'Créer une notification',
                link: '/Creernotifications',
                parentId: 12
            },
            {
                id: 13,
                label: 'Mes notifications',
                link: '/Toutesmesnotif',
                parentId: 12
            },
            {
                id: 13,
                label: 'Toutes les notifications',
                link: '/Allnotification',
                parentId: 12
            },
            {
                id: 13,
                label: 'Administrer Toutes les notifications',
                link: '/Administrertouteslesnotif',
                parentId: 12
            },
        ]
    },







    {
        id: 12,
        label: 'PARAMETRE',
        icon: 'bx bx-cog',
        subItems: [
            {
                id: 13,
                label: 'IMPORTER LES DONNEES',
                link: '/Importerdonnee',
                parentId: 12
            },
            {
                id: 14,
                label: 'SUPPRIMER LES DONNEES',
                link: '/Deletedata',
                parentId: 12
            },
            {
                id: 15,
                label: 'SUPPRIMER CLASSE SANS ELEVE',
                link: '/Classesanseleve',
                parentId: 12
            },
            {
                id: 16,
                label: 'ENREGISTRER INFO ETABLISSEMENT',
                link: '/InfoEtablissement',
                parentId: 12
            },
            {
                id: 17,
                label: 'BLOQUER OU DEBLOQUER UN TRIMESTRE',
                link: '/Bloquertrimestre',
                parentId: 12
            },
            {
                id: 18,
                label: 'REMPLISSAGE TABLE ETAT REMPLISSAGE NOTE',
                link: '/Remplirtableetatnote',
                parentId: 12
            },
            {
                id: 18,
                label: 'Enregistrer/Modifier les horaires',
                link: '/Enregistrerhoraire',
                parentId: 12
            },
            {
                id: 19,
                label: 'Remplir id competence NB',
                link: '/Remplirtablecompetence',
                parentId: 12
            }
        ]
    },






    {
        id: 12,
        label: 'UTILISATEUR',
        icon: 'bx bx-id-card',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer un utilisateur',
                link: '/EnregistrerUser',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les  utilisateurs',
                link: '/ConsultUser',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier un utilisateur',
                link: '/ModifUser',
                parentId: 12
            },
            {
                id: 16,
                label: "Modifier le type d'un utilisateur",
                link: '/Modiftypeuser',
                parentId: 12
            },
            {
                id: 17,
                label: 'Enseignants et utilisateur',
                link: '/Enseignenttouser',
                parentId: 12
            },
            {
                id: 18,
                label: 'utilisateur et classes',
                link: '/Usersgcenseur',
                parentId: 12
            },
            {
                id: 18,
                label: 'Supprimer un utilisateur',
                link: '/Supprimeruser',
                parentId: 12
            }

            
        ]
    },
];
export const MENUenseignant: MenuItem[] = [

    {
        id: 1,
        label: 'ACCUEIL',
        isTitle: true
    },

    {
        id: 123,
        label: 'Tableau de bord',
        icon: 'bx-home-circle',
        link: '/TableauDeBord',
  
    },


    {
        id: 12,
        label: 'TRAVAIL EN GROUPE',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Créer un groupe',
                link: '/Crergroupe',
                parentId: 12
            },
            {
                id: 14,
                label: 'Salon de travail',
                link: '/Salondetravail',
                parentId: 12
            },
        ]
    },

    {
        id: 123,
        label: 'Mon espace',
        icon: 'bx-home-circle',
        link: '/Monespace',
  
    },
    {
        id: 1,
        label: 'VIE SCOLAIRE',
        isTitle: true
    },

    {
        id: 12,
        label: 'NOTES',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les notes',
                link: '/Enregistrernotes',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les notes',
                link: '/Consulternotes',
                parentId: 12
            },
            {
                id: 17,
                label: 'Exporter le PV des notes',
                link: '/ExportPV',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les notes',
                link: '/Modifiernotesv',
                parentId: 12
            },
            {
                id: 18,
                label: 'Imprimer le bordereau de notes',
                link: '/ImprimBordereauNote',
                parentId: 12
            },
            {
                id: 19,
                label: 'Supprimer les notes',
                link: '/Supimernote',
                parentId: 12
            },
            

            
        ]
    },





    {
        id: 12,
        label: 'SUIVI DES ELEVES',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Appel en salle de classe',
                link: '/Appeleleve',
                parentId: 12
            },
            {
                id: 13,
                label: "Consulter la validation de l'Appel",
                link: '/Consultervaliderappel',
                parentId: 12
            },
         
        ]
    },




    {
        id: 1,
        label: 'DONNEES',
        isTitle: true
    },


    {
        id: 12,
        label: 'NOTIFICATION',
        icon: 'bx bx-bell bx-tada',
        subItems: [
            {
                id: 13,
                label: 'Créer une notification',
                link: '/Creernotifications',
                parentId: 12
            },
            {
                id: 13,
                label: 'Mes notifications',
                link: '/Toutesmesnotif',
                parentId: 12
            },
            {
                id: 13,
                label: 'Toutes les notifications',
                link: '/Allnotification',
                parentId: 12
            }
        ]
    },

];

export const MENU_CENSEUR: MenuItem[] = [

    {
        id: 1,
        label: 'ACCUEIL',
        isTitle: true
    },

    {
        id: 123,
        label: 'Tableau de bord',
        icon: 'bx-home-circle',
        link: '/TableauDeBord',
  
    },
    {
        id: 123,
        label: 'Mon espace',
        icon: 'bx-home-circle',
        link: '/Monespace',
  
    },

    {
        id: 12,
        label: 'TRAVAIL EN GROUPE',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Créer un groupe',
                link: '/Crergroupe',
                parentId: 12
            },
            {
                id: 14,
                label: 'Salon de travail',
                link: '/Salondetravail',
                parentId: 12
            },
        ]
    },



    {
        id: 1,
        label: 'VIE SCOLAIRE',
        isTitle: true
    },

    {
        id: 12,
        label: 'NOTES',
        icon: 'bx bx-wallet',
        subItems: [
           
            {
                id: 14,
                label: 'Consulter les notes',
                link: '/Consulternotes',
                parentId: 12
            }

            
        ]
    },


    {
        id: 12,
        label: 'DISCIPLINE',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 14,
                label: 'Consulter les absences',
                link: '/ConsulterAbsence',
                parentId: 12
            },
            {
                id: 17,
                label: 'Consulter CD',
                link: '/ConsulterCD',
                parentId: 12
            }
        ]
    },







    {
        id: 1,
        label: 'DONNEES',
        isTitle: true
    },


    {
        id: 12,
        label: 'NOTIFICATION',
        icon: 'bx bx-bell bx-tada',
        subItems: [
            {
                id: 13,
                label: 'Créer une notification',
                link: '/Creernotifications',
                parentId: 12
            },
            {
                id: 13,
                label: 'Mes notifications',
                link: '/Toutesmesnotif',
                parentId: 12
            },
            {
                id: 13,
                label: 'Toutes les notifications',
                link: '/Allnotification',
                parentId: 12
            },
            {
                id: 13,
                label: 'Administrer Toutes les notifications',
                link: '/Administrertouteslesnotif',
                parentId: 12
            },
        ]
    },

];

export const MENU_SG: MenuItem[] = [

    {
        id: 1,
        label: 'ACCUEIL',
        isTitle: true
    },

    {
        id: 123,
        label: 'Tableau de bord',
        icon: 'bx-home-circle',
        link: '/TableauDeBord',
  
    },
    {
        id: 123,
        label: 'Mon espace',
        icon: 'bx-home-circle',
        link: '/Monespace',
  
    },


    {
        id: 12,
        label: 'TRAVAIL EN GROUPE',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 13,
                label: 'Créer un groupe',
                link: '/Crergroupe',
                parentId: 12
            },
            {
                id: 14,
                label: 'Salon de travail',
                link: '/Salondetravail',
                parentId: 12
            },
        ]
    },




    {
        id: 1,
        label: 'VIE SCOLAIRE',
        isTitle: true
    },

    {
        id: 12,
        label: 'NOTES',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 14,
                label: 'Consulter les notes',
                link: '/Consulternotes',
                parentId: 12
            }
            
        ]
    },


    {
        id: 12,
        label: 'DISCIPLINE',
        icon: 'bx bx-diamond',
        subItems: [
            {
                id: 13,
                label: 'Enregistrer les absences',
                link: '/EnregistrerAbsence',
                parentId: 12
            },
            {
                id: 14,
                label: 'Consulter les absences',
                link: '/ConsulterAbsence',
                parentId: 12
            },
            {
                id: 15,
                label: 'Modifier les absences',
                link: '/ModifAbsence',
                parentId: 12
            },
            {
                id: 16,
                label: 'Enregistrer CD',
                link: '/EnregistrerCD',
                parentId: 12
            },
            {
                id: 17,
                label: 'Consulter CD',
                link: '/ConsulterCD',
                parentId: 12
            },
            {
                id: 18,
                label: 'Modifier CD',
                link: '/ModifierCD',
                parentId: 12
            },
            {
                id: 19,
                label: 'Exporter les absences',
                link: '/Expoerterabs',
                parentId: 12
            }
            ,
            {
                id: 19,
                label: 'Exporter les CD',
                link: '/Expoertercd',
                parentId: 12
            }
        ]
    },


    {
        id: 12,
        label: 'SUIVI DES ELEVES',
        icon: 'bx bx-diamond',
        subItems: [
          
            {
                id: 13,
                label: 'Valider les absences',
                link: '/Validerappel',
                parentId: 12
            },
            {
                id: 13,
                label: "Consulter la validation de l'Appel",
                link: '/Consultervaliderappel',
                parentId: 12
            },
        ]
    },





    {
        id: 1,
        label: 'DONNEES',
        isTitle: true
    },


    {
        id: 12,
        label: 'NOTIFICATION',
        icon: 'bx bx-bell bx-tada',
        subItems: [
            {
                id: 13,
                label: 'Créer une notification',
                link: '/Creernotifications',
                parentId: 12
            },
            {
                id: 13,
                label: 'Mes notifications',
                link: '/Toutesmesnotif',
                parentId: 12
            },
            {
                id: 13,
                label: 'Toutes les notifications',
                link: '/Allnotification',
                parentId: 12
            },
            {
                id: 13,
                label: 'Administrer Toutes les notifications',
                link: '/Administrertouteslesnotif',
                parentId: 12
            },
        ]
    },

];