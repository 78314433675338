import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UrlsService {
 
 public  getclassebyenseignant= "https://intschoolsarl.com/testbackend/public/api/getclassebyenseignant";
  public  login= "https://intschoolsarl.com/testbackend/public/api/loginrestAction";
public  all_message= "https://intschoolsarl.com/testbackend/public/api/messages";
public  add_message= "https://intschoolsarl.com/testbackend/public/api/addmessages";
public  add_eleve= "https://intschoolsarl.com/testbackend/public/api/addelevesgg";
public  all_eleve= "https://intschoolsarl.com/testbackend/public/api/eleves";
public  add_to_liste= "https://intschoolsarl.com/testbackend/public/api/addlisteelevemessage";
public  all_niveaux= "https://intschoolsarl.com/testbackend/public/api/niveaux";
public  all_classse= "https://intschoolsarl.com/testbackend/public/api/classes";
public  classebyniveau= "https://intschoolsarl.com/testbackend/public/api/classebyniveau/";
public  matierebyclasse= "https://intschoolsarl.com/testbackend/public/api/matierebyclasse/";
public  matirebyidmatirer= "https://intschoolsarl.com/testbackend/public/api/matirebyidmatirer/";
public  elevebyclasse= "https://intschoolsarl.com/testbackend/public/api/elevebyclasse/";
public  add_addnotes= "https://intschoolsarl.com/testbackend/public/api/addnotes";
public  getnote= "https://intschoolsarl.com/testbackend/public/api/getnote";
public  getnoteall= "https://intschoolsarl.com/testbackend/public/api/getnoteall";
public  updatenote= "https://intschoolsarl.com/testbackend/public/api/updatenote";
public  verifnotes= "https://intschoolsarl.com/testbackend/public/api/verifnotes";
public  detailmessage= "https://intschoolsarl.com/testbackend/public/api/messages/";
public  listeelevemessage= "https://intschoolsarl.com/testbackend/public/api/listeelevemessage";
public  adduser= "https://intschoolsarl.com/testbackend/public/api/adduser";
public  users= "https://intschoolsarl.com/testbackend/public/api/users";
public  detailuser= "https://intschoolsarl.com/testbackend/public/api/users/";
public  updateuser4= "https://intschoolsarl.com/testbackend/public/api/updateuser";
public  getnotealls= "https://intschoolsarl.com/testbackend/public/api/getnotealls";
public  getnoteclassev= "https://intschoolsarl.com/testbackend/public/api/getnoteclassev";
public  eleveclasseordre= "https://intschoolsarl.com/testbackend/public/api/eleveclasseordre";
public  allvague= "https://intschoolsarl.com/testbackend/public/api/getvagueall";
public  getvaguealleval= "https://intschoolsarl.com/testbackend/public/api/getvaguealleval";
public  getnoteallunevague= "https://intschoolsarl.com/testbackend/public/api/getnoteallunevague";
public  getnoteelevepasliste= "https://intschoolsarl.com/testbackend/public/api/getnoteelevepasliste";
public  adduserenseignant= "https://intschoolsarl.com/testbackend/public/api/adduserenseignant";
public  enseignantall= "https://intschoolsarl.com/testbackend/public/api/enseignantall";
public  allusers= "https://intschoolsarl.com/testbackend/public/api/users";
public  adduseren= "https://intschoolsarl.com/testbackend/public/api/adduseren";
public  add_discipline= "https://intschoolsarl.com/testbackend/public/api/addabs";
public  allabsence= "https://intschoolsarl.com/testbackend/public/api/allabsenceclasse";
public  deletedata= "https://intschoolsarl.com/testbackend/public/api/deletedata";
public  deleteclassesanseleve= "https://intschoolsarl.com/testbackend/public/api/deleteclassesanseleve";
public  updateabs= "https://intschoolsarl.com/testbackend/public/api/updateabs";
public  addcd= "https://intschoolsarl.com/testbackend/public/api/addcd";
public  cdeleve= "https://intschoolsarl.com/testbackend/public/api/cdeleve";
public  updatecd= "https://intschoolsarl.com/testbackend/public/api/updatecd";
public  allinfoe= "https://intschoolsarl.com/testbackend/public/api/allinfoetablissement";
public  addinfoetablissemen= "https://intschoolsarl.com/testbackend/public/api/addinfoetablissemen";
public  getnotetrimestre= "https://intschoolsarl.com/testbackend/public/api/getnotetrimestre";
public  updatenotetri= "https://intschoolsarl.com/testbackend/public/api/updatenotetri";
public  allabsencevv= "https://intschoolsarl.com/testbackend/public/api/allabsencevv";
public  allcdvv= "https://intschoolsarl.com/testbackend/public/api/allcdvv";
public  allbloquer= "https://intschoolsarl.com/testbackend/public/api/allbloquertrimestre";
public  bloquerD= "https://intschoolsarl.com/testbackend/public/api/bloquerD";
public  etatremplisagenote= "https://intschoolsarl.com/testbackend/public/api/etatremplisagenote";
public  etatremplisagenoteall= "https://intschoolsarl.com/testbackend/public/api/etatremplisagenoteall";
public  adduserclasse= "https://intschoolsarl.com/testbackend/public/api/adduserclasse";
public  updateusertype= "https://intschoolsarl.com/testbackend/public/api/updateusertype";
public  classebyuser= "https://intschoolsarl.com/testbackend/public/api/classebyuser";
public  getnoteallss= "https://intschoolsarl.com/testbackend/public/api/getnoteallss";
public  deletenotes= "https://intschoolsarl.com/testbackend/public/api/deletenotes";
public  getnoteallniveau= "https://intschoolsarl.com/testbackend/public/api/getnoteallniveau";
public  getnoteallclasse= "https://intschoolsarl.com/testbackend/public/api/getnoteallclasse";
public  getnoteallniveautoutles= "https://intschoolsarl.com/testbackend/public/api/getnoteallniveautoutles";
public  remplissagetableetatnote= "https://intschoolsarl.com/testbackend/public/api/remplissagetableetatnote";
public  etatremplisagenoteallget= "https://intschoolsarl.com/testbackend/public/api/etatremplisagenoteallget/";
public  uploadphoto= "https://intschoolsarl.com/V1/IMAGEETABLISSEMENT/uploadphoto.php";
public  updateinfoetablissement= "https://intschoolsarl.com/testbackend/public/api/updateinfoetablissement";
public  uploadfichier= "https://intschoolsarl.com/V1/imagesfichiersnotification/uploadphoto.php";
public  addurlfichiermessage= "https://intschoolsarl.com/testbackend/public/api/addurlfichiermessage";
public  allmessageuserbien= "https://intschoolsarl.com/testbackend/public/api/allmessageuser";
public  allmessages= "https://intschoolsarl.com/testbackend/public/api/messages";
public  messagesid= "https://intschoolsarl.com/testbackend/public/api/messages/";
public  allfichiermessage= "https://intschoolsarl.com/testbackend/public/api/allfichiermessage";
public  listeelevemessagevv= "https://intschoolsarl.com/testbackend/public/api/listeelevemessage";
public  deletemessagevv= "https://intschoolsarl.com/testbackend/public/api/deletemessagevv";
public  impoternotes= "https://intschoolsarl.com/testbackend/public/api/impoternotes";
public  addappel= "https://intschoolsarl.com/testbackend/public/api/addappel";
public  deleteuser= "https://intschoolsarl.com/testbackend/public/api/deleteuser";
public  addhoraire= "https://intschoolsarl.com/testbackend/public/api/addhoraire";
public  horaires= "https://intschoolsarl.com/testbackend/public/api/horaires";
public  recupappeldateclasse= "https://intschoolsarl.com/testbackend/public/api/recupappeldateclasse";
public  updateappel= "https://intschoolsarl.com/testbackend/public/api/updateappel";
public  addlesturemessage= "https://intschoolsarl.com/testbackend/public/api/addlesturemessage";
public  absenceappelsv= "https://intschoolsarl.com/testbackend/public/api/absenceappels";
public  recupappelid= "https://intschoolsarl.com/testbackend/public/api/recupappelid";
public  addusermultiple= "https://intschoolsarl.com/testbackend/public/api/addusermultiple";
public  addgroupe= "https://intschoolsarl.com/testbackend/public/api/addgroupe";
public  getgroupebynom= "https://intschoolsarl.com/testbackend/public/api/getgroupeListid/";
public  getgroupeList= "https://intschoolsarl.com/testbackend/public/api/getgroupeList";
public  allgroupe= "https://intschoolsarl.com/testbackend/public/api/allgroupe";
public  addmessagelu= "https://intschoolsarl.com/testbackend/public/api/addmessagelu";
public  addabsenceluv= "https://intschoolsarl.com/testbackend/public/api/addabsencelu";
public  allmessageusernonlu= "https://intschoolsarl.com/testbackend/public/api/allmessageusernonlu";
public  addmessagesalon= "https://intschoolsarl.com/testbackend/public/api/addmessagesalon";
public  alldiscutionuser= "https://intschoolsarl.com/testbackend/public/api/alldiscutionuser";
public  alldiscutiongroupe= "https://intschoolsarl.com/testbackend/public/api/alldiscutiongroupe";
public  uploadfichieretphotosalon= "https://intschoolsarl.com/V1/IMAGESALONDETRAVAIL/uploadphoto.php";
public  allgroupebyuser= "https://intschoolsarl.com/testbackend/public/api/allgroupebyuser";
public  getnoteallmodifier= "https://intschoolsarl.com/testbackend/public/api/getnoteallmodifier";
public  debloqueeval= "https://intschoolsarl.com/testbackend/public/api/debloqueeval";
public  bloqueeval= "https://intschoolsarl.com/testbackend/public/api/bloqueeval";
public  getcompetence= "https://intschoolsarl.com/testbackend/public/api/recupcompetence";
public  getniveaubyclasse= "https://intschoolsarl.com/testbackend/public/api/getniveaubyclasse";


/*
public  deleteclassesanseleve= "http://127.0.0.1:8000/api/deleteclassesanseleve";

*/
/*
public  getclassebyenseignant= "http://127.0.0.1:8000/api/getclassebyenseignant";
public  login= "http://127.0.0.1:8000/api/loginrestAction";
public  all_message= "http://127.0.0.1:8000/api/messages";
public  add_message= "http://127.0.0.1:8000/api/addmessages";
public  add_eleve= "http://127.0.0.1:8000/api/addelevesgg";
public  all_eleve= "http://127.0.0.1:8000/api/eleves";
public  add_to_liste= "http://127.0.0.1:8000/api/addlisteelevemessage";
public  all_niveaux= "http://127.0.0.1:8000/api/niveaux";
public  all_classse= "http://127.0.0.1:8000/api/classes";
public  classebyniveau= "http://127.0.0.1:8000/api/classebyniveau/";
public  matierebyclasse= "http://127.0.0.1:8000/api/matierebyclasse/";
public  matirebyidmatirer= "http://127.0.0.1:8000/api/matirebyidmatirer/";
public  elevebyclasse= "http://127.0.0.1:8000/api/elevebyclasse/";
public  add_addnotes= "http://127.0.0.1:8000/api/addnotes";
public  getnote= "http://127.0.0.1:8000/api/getnote";
public  getnoteall= "http://127.0.0.1:8000/api/getnoteall";
public  updatenote= "http://127.0.0.1:8000/api/updatenote";
public  verifnotes= "http://127.0.0.1:8000/api/verifnotes";
public  detailmessage= "http://127.0.0.1:8000/api/messages/";
public  listeelevemessage= "http://127.0.0.1:8000/api/listeelevemessage";
public  adduser= "http://127.0.0.1:8000/api/adduser";
public  users= "http://127.0.0.1:8000/api/users";
public  detailuser= "http://127.0.0.1:8000/api/users/";
public  updateuser4= "http://127.0.0.1:8000/api/updateuser";
public  getnotealls= "http://127.0.0.1:8000/api/getnotealls";
public  getnoteclassev= "http://127.0.0.1:8000/api/getnoteclassev";
public  eleveclasseordre= "http://127.0.0.1:8000/api/eleveclasseordre";
public  allvague= "http://127.0.0.1:8000/api/getvagueall";
public  getvaguealleval= "http://127.0.0.1:8000/api/getvaguealleval";
public  getnoteallunevague= "http://127.0.0.1:8000/api/getnoteallunevague";
public  getnoteelevepasliste= "http://127.0.0.1:8000/api/getnoteelevepasliste";
public  adduserenseignant= "http://127.0.0.1:8000/api/adduserenseignant";
public  enseignantall= "http://127.0.0.1:8000/api/enseignantall";
public  allusers= "http://127.0.0.1:8000/api/users";
public  adduseren= "http://127.0.0.1:8000/api/adduseren";
public  add_discipline= "http://127.0.0.1:8000/api/addabs";
public  allabsence= "http://127.0.0.1:8000/api/allabsenceclasse";
public  deletedata= "http://127.0.0.1:8000/api/deletedata";
public  deleteclassesanseleve= "http://127.0.0.1:8000/api/deleteclassesanseleve";
public  updateabs= "http://127.0.0.1:8000/api/updateabs";
public  addcd= "http://127.0.0.1:8000/api/addcd";
public  cdeleve= "http://127.0.0.1:8000/api/cdeleve";
public  updatecd= "http://127.0.0.1:8000/api/updatecd";
public  allinfoe= "http://127.0.0.1:8000/api/allinfoetablissement";
public  addinfoetablissemen= "http://127.0.0.1:8000/api/addinfoetablissemen";
public  getnotetrimestre= "http://127.0.0.1:8000/api/getnotetrimestre";
public  updatenotetri= "http://127.0.0.1:8000/api/updatenotetri";
public  allabsencevv= "http://127.0.0.1:8000/api/allabsencevv";
public  allcdvv= "http://127.0.0.1:8000/api/allcdvv";
public  allbloquer= "http://127.0.0.1:8000/api/allbloquertrimestre";
public  bloquerD= "http://127.0.0.1:8000/api/bloquerD";
public  etatremplisagenote= "http://127.0.0.1:8000/api/etatremplisagenote";
public  etatremplisagenoteall= "http://127.0.0.1:8000/api/etatremplisagenoteall";
public  adduserclasse= "http://127.0.0.1:8000/api/adduserclasse";
public  updateusertype= "http://127.0.0.1:8000/api/updateusertype";
public  classebyuser= "http://127.0.0.1:8000/api/classebyuser";
public  getnoteallss= "http://127.0.0.1:8000/api/getnoteallss";
public  deletenotes= "http://127.0.0.1:8000/api/deletenotes";
public  getnoteallniveau= "http://127.0.0.1:8000/api/getnoteallniveau";
public  getnoteallclasse= "http://127.0.0.1:8000/api/getnoteallclasse";
public  getnoteallniveautoutles= "http://127.0.0.1:8000/api/getnoteallniveautoutles";
public  remplissagetableetatnote= "http://127.0.0.1:8000/api/remplissagetableetatnote";
public  etatremplisagenoteallget= "http://127.0.0.1:8000/api/etatremplisagenoteallget/";
public  uploadphoto= "https://intschoolsarl.com/V1/IMAGEETABLISSEMENT/uploadphoto.php";
public  updateinfoetablissement= "http://127.0.0.1:8000/api/updateinfoetablissement";
public  uploadfichier= "https://intschoolsarl.com/V1/imagesfichiersnotification/uploadphoto.php";
public  addurlfichiermessage= "http://127.0.0.1:8000/api/addurlfichiermessage";
public  allmessageuserbien= "http://127.0.0.1:8000/api/allmessageuser";
public  allmessages= "http://127.0.0.1:8000/api/messages";
public  messagesid= "http://127.0.0.1:8000/api/messages/";
public  allfichiermessage= "http://127.0.0.1:8000/api/allfichiermessage";
public  listeelevemessagevv= "http://127.0.0.1:8000/api/listeelevemessage";
public  deletemessagevv= "http://127.0.0.1:8000/api/deletemessagevv";
public  impoternotes= "http://127.0.0.1:8000/api/impoternotes";
public  addappel= "http://127.0.0.1:8000/api/addappel";
public  deleteuser= "http://127.0.0.1:8000/api/deleteuser";
public  addhoraire= "http://127.0.0.1:8000/api/addhoraire";
public  horaires= "http://127.0.0.1:8000/api/horaires";
public  recupappeldateclasse= "http://127.0.0.1:8000/api/recupappeldateclasse";
public  updateappel= "http://127.0.0.1:8000/api/updateappel";
public  addlesturemessage= "http://127.0.0.1:8000/api/addlesturemessage";
public  absenceappelsv= "http://127.0.0.1:8000/api/absenceappels";
public  recupappelid= "http://127.0.0.1:8000/api/recupappelid";
public  addusermultiple= "http://127.0.0.1:8000/api/addusermultiple";
public  addgroupe= "http://127.0.0.1:8000/api/addgroupe";
public  getgroupebynom= "http://127.0.0.1:8000/api/getgroupeListid/";
public  getgroupeList= "http://127.0.0.1:8000/api/getgroupeList";
public  allgroupe= "http://127.0.0.1:8000/api/allgroupe";
public  addmessagelu= "http://127.0.0.1:8000/api/addmessagelu";
public  addabsenceluv= "http://127.0.0.1:8000/api/addabsencelu";
public  allmessageusernonlu= "http://127.0.0.1:8000/api/allmessageusernonlu";
public  addmessagesalon= "http://127.0.0.1:8000/api/addmessagesalon";
public  alldiscutionuser= "http://127.0.0.1:8000/api/alldiscutionuser";
public  alldiscutiongroupe= "http://127.0.0.1:8000/api/alldiscutiongroupe";
public  uploadfichieretphotosalon= "https://intschoolsarl.com/V1/IMAGESALONDETRAVAIL/uploadphoto.php";
public  allgroupebyuser= "http://127.0.0.1:8000/api/allgroupebyuser";
public  getnoteallmodifier= "http://127.0.0.1:8000/api/getnoteallmodifier";
public  debloqueeval= "http://127.0.0.1:8000/api/debloqueeval";
public  bloqueeval= "http://127.0.0.1:8000/api/bloqueeval";
public  getcompetence= "http://127.0.0.1:8000/api/recupcompetence";*/



  constructor() { }
}
